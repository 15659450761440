import styled from "styled-components";
import React from "react";
import { systemAudioPlayer } from "../../../utils/system-audio-player";

export const SmallButtonDiv = styled.div`
  font-family: dqwdqwdqwdasd;
  color: #6a6863;
  font-size: 20px;
  background: url(${"./img/game/main-menu/escape-button.png"}) no-repeat center;
  background-size: 100% 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 130px;
  height: 40px;
  white-space: nowrap;
  padding: 0 12px;

  :hover {
    background-image: url(${"./img/game/main-menu/escape-button-hover.png"});
    color: #93704b;
  }
`;

export function SmallButton(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <SmallButtonDiv
      {...props}
      onClick={(e) => {
        // eslint-disable-next-line react/prop-types
        props.onClick?.(e);
        systemAudioPlayer.click();
      }}
      onMouseEnter={(e) => {
        // eslint-disable-next-line react/prop-types
        props.onMouseEnter?.(e);
        systemAudioPlayer.focus();
      }}
    />
  );
}

export type IPlayParams = {
  src: string;
  loop: boolean;
  onEnded?: () => void;
};

type IActiveList = IPlayParams & { audio: HTMLAudioElement };

export class AudioPlayer {
  private activeList: IActiveList[] = [];
  private onEndAudios = new WeakMap<HTMLAudioElement, () => void>();

  private volume = 1;

  setVolume(volume: number): void {
    this.volume = volume;
    this.activeList.forEach((a) => {
      a.audio.volume = volume;
    });
  }

  play(params: IPlayParams): void {
    // this.stop(params.src);

    const audio = document.createElement("audio");
    audio.src = params.src;
    audio.autoplay = true;
    audio.loop = params.loop;
    audio.volume = this.volume;

    if (params.onEnded) {
      audio.onended = params.onEnded;
      this.onEndAudios.set(audio, params.onEnded);
    }

    // document.body.appendChild(audio);

    this.activeList.push({
      ...params,
      audio,
    });
  }

  stop(src: string): void {
    const active = this.activeList.find((a) => a.src === src);
    if (active) {
      const cb = this.onEndAudios.get(active.audio);

      if (cb) {
        active.audio.removeEventListener("ended", cb);
        this.onEndAudios.delete(active.audio);
        cb();
      }

      active.audio.pause();
      // document.body.removeChild(active.audio);
      this.activeList.splice(this.activeList.indexOf(active), 1);
    }
  }
}

export const backgroundAudioPlayer = new AudioPlayer();
backgroundAudioPlayer.setVolume(0.9);
export const eventsAudioPlayer = new AudioPlayer();

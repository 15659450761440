import React, { PropsWithChildren, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { electronApi } from "../utils/electron-api";

export type ILinkProps = PropsWithChildren<{
  to: string;
  target?: "_blank";
  className?: string;
  canActivate?(): Promise<boolean>;
  onMouseEnter?(): void;
  onClick?(): void;
}>;

export const Link = ({
  children,
  to,
  className,
  target,
  canActivate,
  onMouseEnter,
  onClick,
}: ILinkProps) => {
  const navigate = useNavigate();
  const handleOnClick = useCallback(async () => {
    onClick?.();

    if (canActivate && !(await canActivate())) {
      return;
    }

    if (target === "_blank") {
      if (electronApi) {
        electronApi.openInBrowser(to);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.open(to, "_blank").focus();
      }
    } else {
      navigate(to);
    }
  }, [canActivate, navigate, target, to]);

  return (
    <div
      className={className}
      onClick={handleOnClick}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </div>
  );
};

import React from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { GameWindow } from "../../components/game/GameWindow";
import { Popup } from "../../components/game/Popup";
import { ILinkProps, Link } from "../../components/Link";
import { backgroundAudioPlayer } from "../../utils/audio-player";
import { LocalStorage } from "../../stores/local-storage";
import { LOCAL_STORAGE_GAME_STATE_NAME } from "./Game";
import { ConfirmService } from "../../components/Confirm";
import { systemAudioPlayer } from "../../utils/system-audio-player";
import { electronApi } from "../../utils/electron-api";
import { VKShowAds } from "../../api/vkBridge";

const MenuLeft = styled.div`
  background: #fff url(${"./img/game/main-menu/menu-hero.png"}) no-repeat center;
  background-size: cover;
`;
const MenuRight = styled.div`
  background: url(${"./img/game/main-menu/main-menu-right-bg.png"}) no-repeat
    center;
  background-size: cover;
  position: relative;
  display: grid;
  grid-template-rows: 120fr 570fr 100fr 90fr 240fr;
  grid-template-columns: 4fr 272fr 301fr 195fr;
  grid-template-areas:
    ". . . ."
    ". menuList menuList ."
    ". . . ."
    ". . menuEscape ."
    ". . . .";
`;
const MenuList = styled.div`
  background: url(${"./img/game/main-menu/menu-list-bg.png"}) no-repeat center;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  grid-area: menuList;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > .list-item {
    margin: 3% 0;
  }

  .disabled-item {
    opacity: 0.5;
  }
`;

const Authors = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  font-family: Lobster;
  font-size: 22px;
  color: #343332;
`;
const MenuListItem = styled.div<{ disabled?: boolean }>`
  text-align: center;
  color: #6a6863;
  font-family: dqwdqwdqwdasd;
  font-size: 48px;
  text-transform: uppercase;

  ${(props) =>
    props.disabled
      ? `opacity: .5`
      : `:hover {
    color: white;
    text-shadow: 2px 2px 0px rgba(227, 204, 103, 1);
  }`}
`;
const MenuListItemBrownHover = styled(MenuListItem)`
  :hover {
    text-shadow: none;
    color: #93704b;
  }
`;
const MenuListItemLink = (props: ILinkProps) => {
  return (
    <Link
      {...props}
      className={props?.className + " list-item"}
      onMouseEnter={systemAudioPlayer.focus}
      onClick={systemAudioPlayer.startGameButton}
    />
  );
};
const MenuListItemExitLink = (props: ILinkProps) => {
  return (
    <div
      onClick={() => {
        electronApi?.closeApp?.();
      }}
    >
      <MenuListItemLink {...props} />
    </div>
  );
};

const MenuEscape = styled.div`
  background: url(${"./img/game/main-menu/escape-button.png"}) no-repeat center;
  background-size: 100% 100%;
  grid-area: menuEscape;
  flex-direction: column;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;

  :hover {
    background: url(${"./img/game/main-menu/escape-button-hover.png"}) no-repeat
      center;
    background-size: 100% 100%;
  }
`;

type IState = {
  popupOpen: boolean;
};

export class GameMainMenu extends React.Component<any, IState> {
  state = {
    popupOpen: false,
  };

  private mainMenuSound = "./mp3/sound/Menu.mp3";

  private audio?: HTMLAudioElement;

  componentDidMount() {
    backgroundAudioPlayer.play({
      src: this.mainMenuSound,
      loop: true,
    });

    if ((window as any).playAd) {
      (window as any).playAd = false;
      VKShowAds();
    }
  }

  componentWillUnmount() {
    backgroundAudioPlayer.stop(this.mainMenuSound);
  }

  render() {
    const hasSavedGame = !!LocalStorage.get(LOCAL_STORAGE_GAME_STATE_NAME);

    return (
      <GameWindow>
        <MenuLeft>
          <Popup
            size={"small"}
            isOpen={this.state.popupOpen}
            onClose={() => {
              this.setState({
                popupOpen: false,
              });
            }}
          >
            fasfafasfasdasd
          </Popup>
        </MenuLeft>
        <MenuRight>
          <Routes>
            <Route
              path="/"
              element={
                <React.Fragment>
                  <MenuList>
                    <MenuListItemLink
                      canActivate={async () => {
                        if (hasSavedGame) {
                          return await new Promise<boolean>((res) => {
                            ConfirmService.confirm({
                              message: `Уже есть прогресс.<br /> Начать новую игру?`,
                              buttons: ["Да", "Нет"],
                              cb: (index) => res(index === 0),
                            });
                          });
                        }
                        return true;
                      }}
                      to={"/game"}
                    >
                      <MenuListItem>Новая игра</MenuListItem>
                    </MenuListItemLink>
                    <MenuListItemLink
                      canActivate={async () => hasSavedGame}
                      to={"/game?continue-game=1"}
                    >
                      <MenuListItem disabled={!hasSavedGame}>
                        Продолжить
                      </MenuListItem>
                    </MenuListItemLink>
                    <MenuListItemLink to={"/records"}>
                      <MenuListItem>Рекорды</MenuListItem>
                    </MenuListItemLink>
                    {/* <MenuListItemLink
                      to={"/game-menu/authors"}
                      canActivate={async () => false}
                    >
                      <MenuListItem disabled={true}>Выбор языка</MenuListItem>
                    </MenuListItemLink> */}
                    <MenuListItemLink to={"/game-menu/authors"}>
                      <MenuListItem>Авторы</MenuListItem>
                    </MenuListItemLink>
                  </MenuList>
                  {/* <MenuEscape>
                    <MenuListItemExitLink to={"/"}>
                      <MenuListItemBrownHover>Выход</MenuListItemBrownHover>
                    </MenuListItemExitLink>
                  </MenuEscape> */}
                </React.Fragment>
              }
            />

            <Route
              path="/authors"
              element={
                <React.Fragment>
                  <MenuList>
                    <Authors>
                      <div>Продюсер: Антон Панов</div>
                      <div>
                        Программисты: Александр Чертков, Руслан Бурангулов
                      </div>
                      <div>Композитор: Евгений Калашов</div>{" "}
                      <div>Художник - иллюстратор: Людмила Панова</div>{" "}
                      <div>UI/UX - дизайн: Елена Назарова</div>
                      <div>Сценарист: Антон Панов</div>{" "}
                      <div>Копирайт: Евгений Калашов</div>
                      <div>Тестировщики: Евгений Калашов, Михаил Пермяков</div>
                    </Authors>
                    {/*<MenuListItemLink*/}
                    {/*  to={"https://webmedved.ru/"}*/}
                    {/*  target="_blank"*/}
                    {/*>*/}
                    {/*  <MenuListItem>webmedved.ru</MenuListItem>*/}
                    {/*</MenuListItemLink>*/}
                  </MenuList>
                  <MenuEscape>
                    <MenuListItemLink to={"/game-menu"}>
                      <MenuListItemBrownHover>Назад</MenuListItemBrownHover>
                    </MenuListItemLink>
                  </MenuEscape>
                </React.Fragment>
              }
            />
            <Route
              path="/asd"
              element={<React.Fragment>asdasd</React.Fragment>}
            />
          </Routes>
        </MenuRight>
      </GameWindow>
    );
  }
}

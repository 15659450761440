export class LocalStorage {
  static get(name: string): null | any {
    const lsMainConfig = localStorage.getItem(name);

    return (lsMainConfig && JSON.parse(lsMainConfig)) || null;
  }

  static set(name: string, value: any): void {
    localStorage.setItem(name, JSON.stringify(value));
  }
}

import { AudioPlayer } from "./audio-player";

class SystemAudioPlayer extends AudioPlayer {
  click = () => {
    this.play({
      loop: false,
      src: "./mp3/effects/ClickButton.mp3",
    });
  };

  focus = () => {
    this.play({
      loop: false,
      src: "./mp3/effects/FocusButton.mp3",
    });
  };

  friendClick = () => {
    this.play({
      loop: false,
      src: "./mp3/effects/FriendClick.mp3",
    });
  };

  lose = () => {
    this.play({
      loop: false,
      src: "./mp3/effects/Lose.mp3",
    });
  };

  startGameButton = () => {
    this.play({
      loop: false,
      src: "./mp3/effects/StartGameButton.mp3",
    });
  };

  win = () => {
    this.play({
      loop: false,
      src: "./mp3/effects/Win.mp3",
    });
  };
}

export const systemAudioPlayer = new SystemAudioPlayer();

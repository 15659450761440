import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Confirm } from "../Confirm";

const WIDTH = 1920;
const HEIGHT = 1080;

const GlobalStyle = createGlobalStyle`
  body {
    cursor: url("${"./img/game/cursor/default-32.png"}"), pointer;
    user-select: none;

    img {
      pointer-events: none;
    }

    h3 {
      margin: 0;
      font-family: dqwdqwdqwdasd;
      font-size: 24px;
      text-transform: uppercase;
      text-align: center;
    }
  }
`;
const GameWindowDiv = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("./img/game/main-background.jpg"),
    linear-gradient(60.18deg, #ebe5db 28.66%, #e6e6e6 89.59%);
  background-size: cover;
`;
const GameWrapper = styled.div<Pick<Props, "isFullWidthWrapperColumns">>`
  position: fixed;
  left: 0;
  top: 0;
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  transform-origin: left top;
  transition: transform 0s;
  display: grid;
  grid-template-columns: ${(props) =>
    !!props.isFullWidthWrapperColumns ? "1fr" : "3fr minmax(768px, 2fr)"};
  grid-template-rows: 1fr;
  gap: 0;
`;

type Props = {
  isFullWidthWrapperColumns?: boolean;
  children: React.ReactNode;
};

export class GameWindow extends React.Component<Props, any> {
  gameContainerRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.fixAccordingWidowWidth();

    window.addEventListener("resize", this.fixAccordingWidowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.fixAccordingWidowWidth);
  }

  fixAccordingWidowWidth = () => {
    const container: HTMLDivElement | null = this.gameContainerRef.current;

    if (!container) {
      return;
    }

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const scale = Math.min(windowWidth / WIDTH, windowHeight / HEIGHT);

    container.style.transform = `scale(${scale})`;

    const verticalScaling = WIDTH / HEIGHT > windowWidth / windowHeight;
    console.log(verticalScaling, "verticalScaling");
    if (verticalScaling) {
      container.style.left = "0";
      container.style.top = `${windowHeight / 2 - (HEIGHT / 2) * scale}px`;
    } else {
      container.style.left = `${windowWidth / 2 - (WIDTH / 2) * scale}px`;
      container.style.top = "0";
    }
  };

  render() {
    return (
      <GameWindowDiv>
        <GlobalStyle />
        <GameWrapper
          ref={this.gameContainerRef}
          id={"game-wrapper"}
          isFullWidthWrapperColumns={this.props.isFullWidthWrapperColumns}
        >
          {this.props.children}
          <div style={{ position: "absolute" }}>
            <Confirm />
          </div>
        </GameWrapper>
      </GameWindowDiv>
    );
  }
}

import React from "react";
import styled from "styled-components";

const Face = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const FaceTop = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  img {
    width: 190px;
    /*height: 280px;
    object-fit: cover;*/
  }
`;

export function PopupFace(props: { img: string; title: string; text: string }) {
  return (
    <Face>
      <FaceTop>
        <img src={props.img} alt="" />
        <span>{props.title}</span>
      </FaceTop>
      <div>{props.text}</div>
    </Face>
  );
}

import React, { Component } from "react";
import { NavigateFunction } from "react-router";
import styled from "styled-components";
import { BASE_RECORDS_URL, RecordType } from "../../api/records";
import { loadRating } from "../../api/service";
import { GameWindow } from "../../components/game/GameWindow";
import { backgroundAudioPlayer } from "../../utils/audio-player";

const RecordsContainer = styled.div`
  background: url("./img/game/game/border-bg.png") no-repeat top left,
    url("./img/game/game/border-bg.png") no-repeat bottom left,
    url("./img/game/game/border-bg-vertical.png") no-repeat top right,
    url("./img/game/game/border-bg-vertical.png") no-repeat left top,
    linear-gradient(60.18deg, #ebe5db 28.66%, #e6e6e6 89.59%);
  padding: 36px;
`;

const Title = styled.caption`
  margin: 0;
  color: #000000;
  font-family: dqwdqwdqwdasd;
  font-size: 64px;
  line-height: 113px;
  text-transform: uppercase;
`;

const Close = styled.div`
  background: url("${"./img/game/popup/close.png"}") no-repeat center;
  background-size: 100% 100%;
  height: 50px;
  width: 50px;
  top: 56px;
  right: 56px;
  position: absolute;

  :hover {
    background-image: url("${"./img/game/popup/close-hover.png"}");
  }
`;

const Table = styled.table<{ isLarge?: boolean }>`
  position: relative;
  display: block;
  height: ${(props) => (props.isLarge ? "781px" : "227px")};
  overflow-y: scroll;
  border-collapse: collapse;

  &::-webkit-scrollbar {
    width: 0;
  }

  & td,
  & th {
    border: 1px dashed #b8b4ae;
  }
  & th {
    border-top: 0;
  }
  & tr:last-child td {
    border-bottom: 0;
  }
  & tr td:first-child,
  & tr th:first-child {
    border-left: 0;
  }
  & tr td:last-child,
  & tr th:last-child {
    border-right: 0;
  }
`;

const Header = styled.tr`
  height: 114px;
  background: #d9d9d9;
`;

const Number = styled.th`
  width: 96px;
  color: #000000;
  font-family: dqwdqwdqwdasd;
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  text-transform: uppercase;
`;

const Name = styled.th`
  width: 548px;
  color: #000000;
  font-family: dqwdqwdqwdasd;
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  text-transform: uppercase;
`;

const Record = styled.th`
  width: 300px;
`;

const RecordImage = styled.img`
  height: 50px;
`;

const RecordTitle = styled.div`
  font-family: dqwdqwdqwdasd;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
`;

const Row = styled.tr`
  height: 60px;
`;

const NumberCell = styled.td`
  width: 96px;
  font-family: dqwdqwdqwdasd;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
  text-align: center;
`;

const NameCell = styled.td`
  width: 548px;
  padding-left: 30px;
  max-width: 548px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const PlayerName = styled.span`
  padding-left: 15px;
  font-family: "Lobster";
  font-size: 18px;
  line-height: 50px;
  letter-spacing: 0.1em;
  color: #000000;
  vertical-align: text-bottom;
`;

const StatCell = styled.td`
  width: 300px;
  font-family: "Lobster";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: #000000;
  text-align: center;
`;

const ErrorContainer = styled.tbody`
  display: table;
  width: 100%;
  height: 100%;
`;

const Error = styled.td`
  font-family: "Lobster";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: red;
  text-align: center;
`;

type Props = any;

type State = {
  data: RecordType[] | null;
  error: string;
};

class Records extends Component<Props, State> {
  state: State = {
    data: null,
    error: "",
  };

  private mainMenuSound = "./mp3/sound/Menu.mp3";

  private audio?: HTMLAudioElement;

  componentDidMount() {
    backgroundAudioPlayer.play({
      src: this.mainMenuSound,
      loop: true,
    });

    loadRating(
      (data) =>
        this.setState({
          data,
          error: "",
        }),
      (error) =>
        this.setState({
          data: null,
          error,
        }),
    );
  }

  componentWillUnmount() {
    backgroundAudioPlayer.stop(this.mainMenuSound);
  }

  render() {
    const { data, error } = this.state;

    return (
      <GameWindow isFullWidthWrapperColumns={true}>
        <RecordsContainer>
          <Table>
            <Title>рекорды</Title>
            <thead>
              <Header>
                <Number>№</Number>
                <Name>Имя</Name>
                <Record>
                  <RecordImage src="./img/game/stats/kazna.png" />
                  <RecordTitle>Казна</RecordTitle>
                </Record>
                <Record>
                  <RecordImage src="./img/game/stats/sword.png" />
                  <RecordTitle>Сила дружины</RecordTitle>
                </Record>
                <Record>
                  <RecordImage src="./img/game/stats/man.png" />
                  <RecordTitle>Отношения со смердами</RecordTitle>
                </Record>
                <Record>
                  <RecordImage src="./img/game/stats/boyar.png" />
                  <RecordTitle>Отношения с боярами</RecordTitle>
                </Record>
              </Header>
            </thead>
          </Table>
          <Table isLarge>
            {data?.map((record) => (
              <tbody key={record.position}>
                <Row>
                  <NumberCell>{record.position}</NumberCell>
                  <NameCell>
                    <Avatar src={record.avatar} />
                    <PlayerName>{record.full_name}</PlayerName>
                  </NameCell>
                  <StatCell>{record.treasury}</StatCell>
                  <StatCell>{record.squad_power}</StatCell>
                  <StatCell>{record.relationship_with_smerds}</StatCell>
                  <StatCell>{record.relationship_with_boyars}</StatCell>
                </Row>
              </tbody>
            ))}
            {!!error && (
              <ErrorContainer>
                <tr>
                  <Error>{error}</Error>
                </tr>
              </ErrorContainer>
            )}
          </Table>
          <Close
            onClick={() => {
              window.location.hash = "/game-loader";
            }}
          />
        </RecordsContainer>
      </GameWindow>
    );
  }
}

export default Records;

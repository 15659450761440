import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { systemAudioPlayer } from "../../utils/system-audio-player";

const PopupGrid = styled.div<Pick<IProps, "size">>`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;
const PopupContentContainer = styled.div<Pick<IProps, "size">>`
  position: fixed;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  grid-area: content;
  align-content: center;
  background: url("${(props) =>
      props.size === "small"
        ? "./img/game/popup/small-size-bg.png"
        : "./img/game/popup/medium-size-bg.png"}")
    no-repeat center;
  height: ${(props) => (props.size === "small" ? "319px" : "570px")};
  width: 520px;
  background-size: 100% 100%;
  padding: 85px 30px 30px;
  box-shadow: black 0px 0px 45px 4px;
  border-radius: 20px;
`;

const Close = styled.div`
  background: url("${"./img/game/popup/close.png"}") no-repeat center;
  background-size: 100% 100%;
  height: 50px;
  width: 50px;
  top: 30px;
  right: 30px;
  position: absolute;

  :hover {
    background-image: url("${"./img/game/popup/close-hover.png"}");
  }
`;

const Content = styled.div``;

type IProps = PropsWithChildren<{
  size?: "medium" | "small";
  isOpen: boolean;
  onClose: () => void;
}>;

export function Popup(props: IProps) {
  if (!props.isOpen) return null;

  return (
    <>
      <PopupGrid size={props.size} onClick={props.onClose}></PopupGrid>
      <PopupContentContainer size={props.size}>
        <Close
          onClick={() => {
            systemAudioPlayer.click();
            props.onClose();
          }}
          onMouseEnter={() => {
            systemAudioPlayer.focus();
          }}
        />
        <Content>{props.children}</Content>
      </PopupContentContainer>
    </>
  );
}

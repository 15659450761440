import { GameOverType, IAnswer, IEvent } from "../stores/main-config.store";
import { GameState } from "./game-state";
import {
  backgroundAudioPlayer,
  eventsAudioPlayer,
} from "../utils/audio-player";

export class Game extends GameState {
  get isGameOver(): GameOverType {
    return this.gameState.isGameOver;
  }
  set isGameOver(value: GameOverType) {
    this.gameState.isGameOver = value;
  }
  get currentEvent(): IEvent | undefined {
    return this.gameState.currentEvent;
  }
  set currentEvent(value: IEvent | undefined) {
    this.gameState.currentEvent = value;
  }
  get currentAnswer(): IAnswer | undefined {
    return this.gameState.currentAnswer;
  }
  set currentAnswer(value: IAnswer | undefined) {
    this.gameState.currentAnswer = value;
  }

  private currentAudioUrl: string | undefined;

  exit() {
    this.playAudioOnce();
  }

  chooseAnswer(answer: IAnswer) {
    if (this.currentAnswer || !this.currentEvent) {
      return;
    }

    this.isGameOver = this.giveAnswer(this.currentEvent, answer);
    this.currentAnswer = answer;

    this.playAudioOnce(answer.voiceUrl.ru);
  }

  moveOnWithoutAnswers = () => {
    if (!this.currentEvent) {
      return;
    }

    this.isGameOver = this.giveAnswer(this.currentEvent);
    this.moveOn();
  };

  moveOn() {
    if (this.isGameOver) {
      return console.log("Ты проиграл! Ходов больше нет!");
    }
    const currentEvent = this.getCurrentEvent();
    this.playAudioOnce(currentEvent.voiceUrl.ru);

    this.currentEvent = currentEvent;
    this.currentAnswer = undefined;

    if (currentEvent.answers.length === 1) {
      this.chooseAnswer(currentEvent.answers[0]);
    }
  }

  private playAudioOnce(audioUrl?: string) {
    if (this.currentAudioUrl) {
      eventsAudioPlayer.stop(this.currentAudioUrl);
      this.currentAudioUrl = undefined;
    }

    if (!audioUrl) return;

    backgroundAudioPlayer.setVolume(0.6);
    this.currentAudioUrl = audioUrl;
    eventsAudioPlayer.play({
      src: audioUrl,
      loop: false,
      onEnded: this.onAudioEnd,
    });
  }

  private onAudioEnd = () => {
    backgroundAudioPlayer.setVolume(0.9);
  };
}

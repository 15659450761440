import { Dispatch, SetStateAction, useState } from "react";
import { Popup } from "./game/Popup";
import React from "react";
import { SmallButton } from "./game/buttons/small-button";
import styled from "styled-components";

type IConfirmData = {
  message: string;
  cb: (result: number) => void;
  buttons: string[];
} | null;

export class ConfirmService {
  static setOpen: Dispatch<SetStateAction<IConfirmData>>;

  static confirm(data: IConfirmData) {
    this.setOpen(data);
  }
}

const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;
const H3 = styled.h3`
  font-size: 36px;
  line-height: 39px;
`;
const SOmeDiv = styled.div`
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export function Confirm() {
  const [data, setConfirmData] = useState<IConfirmData>(null);
  ConfirmService.setOpen = setConfirmData;

  if (!data) return null;

  return (
    <Popup size="small" isOpen onClose={() => ConfirmService.setOpen(null)}>
      <SOmeDiv>
        <H3 dangerouslySetInnerHTML={{ __html: data.message }} />
        <ButtonsContainer>
          {data.buttons.map((button, index) => {
            return (
              <SmallButton
                key={button}
                onClick={() => {
                  ConfirmService.setOpen(null);
                  data.cb(index);
                }}
              >
                {button}
              </SmallButton>
            );
          })}
        </ButtonsContainer>
      </SOmeDiv>
    </Popup>
  );
}

import axios from "axios";

export const BASE_RECORDS_URL = "https://ttom.webmedved.ru";

export const recordsApi = axios.create({
  // withCredentials: true,
  baseURL: BASE_RECORDS_URL,
});

export const RECORDS_API_PATHS = {
  RATING: "/get_rating.php",
  RELATIONSHIP: "/get_relationships.php",
  VK_AUTH: "/vk_auth.php",
  UPDATE_RATING: "/update_rating.php",
};

export enum StatusCodes {
  success = 200,
  noContent = 204,
  badRequest = 400,
  unauthorized = 401,
  unprocessable = 422,
}

type SuccessResponse<T> = {
  success: true;
  status_code: StatusCodes.success;
  action: string;
  message: string;
  data: T;
};

export type RecordType = {
  position: number;
  avatar: string;
  full_name: string;
  treasury: string;
  squad_power: string;
  relationship_with_smerds: string;
  relationship_with_boyars: string;
  is_complete_campaign: string;
};

export type RelationshipType = {
  name: string;
};

export type VkParamsType = { get_params: string };

export type RatingUpdateDataType = {
  vk_id: string;
  treasury: number;
  squad_power: number;
  relationship_with_smerds_id: number;
  relationship_with_boyars_id: number;
  is_complete_campaign: number;
};

export const getRating = () =>
  recordsApi.get<SuccessResponse<RecordType[]>>(`${RECORDS_API_PATHS.RATING}`);

export const getRelationship = () =>
  recordsApi.get<SuccessResponse<RelationshipType[]>>(
    `${RECORDS_API_PATHS.RELATIONSHIP}`,
  );

export const setVkAuthParams = (data: FormData) =>
  recordsApi.post(`${RECORDS_API_PATHS.VK_AUTH}`, data);

export const sendRating = (data: FormData) =>
  recordsApi.post(`${RECORDS_API_PATHS.UPDATE_RATING}`, data);

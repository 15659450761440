import React from "react";
import { GameWindow } from "../../components/game/GameWindow";
import styled from "styled-components";
import { Game } from "../../game/game";
import { mainConfigStore } from "../../hooks/use-main-config-store";
import { SmallButton } from "../../components/game/buttons/small-button";
import { Gerbs } from "../../components/game/buttons/Gerb";
import { ConfirmService } from "../../components/Confirm";
import { LocalStorage } from "../../stores/local-storage";
import { GameOverType } from "../../stores/main-config.store";
import { backgroundAudioPlayer } from "../../utils/audio-player";
import { systemAudioPlayer } from "../../utils/system-audio-player";
import { loadRelationship, updateRating } from "../../api/service";
import { RelationshipType } from "../../api/records";
import isValidNumber from "../../utils/is-valid-number";
import { VKCheckAds } from "../../api/vkBridge";

export const GameContainer = styled.div`
  font-family: Lobster;
  font-size: 22px;
  display: grid;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  grid-template-columns: 36px 33fr 36px 20fr 36px;
  grid-template-rows: 36px 474fr 36px 318fr 36px 144fr 36px;
  grid-template-areas:
    "xbt xbt xbt xbt xbt"
    "ybl mp ybm ep ybr"
    "ybl mp ybm xbm1 ybr"
    "ybl mp ybm et ybr"
    "ybl xbm2 ybm et ybr"
    "ybl st ybm et ybr"
    "xbb xbb xbb xbb xbb";
`;

const XBorder = styled.div`
  background: url(${"./img/game/game/border-bg.png"}) center;
  grid-area: xbt;
`;
const XBorderBottom = styled(XBorder)`
  grid-area: xbb;
`;
const XBorderMiddle1 = styled(XBorder)`
  grid-area: xbm1;
`;
const XBorderMiddle2 = styled(XBorder)`
  grid-area: xbm2;
`;
const YBorder = styled.div`
  background: url(${"./img/game/game/border-bg-vertical.png"}) center;
  grid-area: ybl;
`;
const YBorderMiddle = styled(YBorder)`
  grid-area: ybm;
`;
const YBorderRight = styled(YBorder)`
  grid-area: ybr;
`;

const MapContainer = styled.div`
  grid-area: mp;
  position: relative;

  & > img {
    width: 100%;
    height: 100%;
  }
`;
const StatsContainer = styled.div`
  grid-area: st;
  white-space: nowrap;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 14px 30px;

  .stat {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 24px;

    img {
      height: 90px;
    }
  }
`;
const EventPictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: ep;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
`;
const EventTextContainer = styled.div`
  grid-area: et;
  padding: 30px 20px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 14px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #8a8783;
  }

  h3 {
    font-family: dqwdqwdqwdasd;
    font-size: 36px;
    line-height: 39px;
    margin: 0 0 10px;
    text-align: center;
  }
  p {
    margin: 0 0 20px;
    white-space: pre-line;
  }

  .answer {
    margin: 0 0 20px;
    padding-left: 44px;
    position: relative;

    :before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 24px;
      height: 24px;
      background-image: url("./img/game/game/radio.png");
    }

    :hover {
      :before {
        background-image: url("./img/game/game/radio-hover.png");
      }
    }
  }
`;

const LiveMap = styled.iframe`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
`;

export const LOCAL_STORAGE_GAME_STATE_NAME = "currentGameState";

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export class GameGame extends React.Component<any, any> {
  game = new Game(mainConfigStore.mainConfig);
  useLocalStorage = false;
  // runAdsOnOver = false;

  componentDidMount() {
    this.useLocalStorage = window.location.href.includes("continue-game");
    if (this.useLocalStorage) {
      const gameState = LocalStorage.get(LOCAL_STORAGE_GAME_STATE_NAME);

      if (gameState) {
        this.game = new Game(mainConfigStore.mainConfig, gameState);
      }
    }

    backgroundAudioPlayer.play({
      src: "./mp3/sound/1-4.mp3",
      loop: true,
    });

    document.body.requestFullscreen();
    this.game.moveOn();
    this.forceUpdate();

    // загрузка взаимоотношений для рейтинга если ранее не удалось загрузить
    if (!(window as any).relationship) {
      loadRelationship((relationship) => {
        (window as any).relationship = relationship;
      });
    }
  }

  componentDidUpdate() {
    LocalStorage.set(
      LOCAL_STORAGE_GAME_STATE_NAME,
      this.game.getClonedGameState(),
    );
  }

  componentWillUnmount() {
    this.game.exit();
    document.exitFullscreen();
    backgroundAudioPlayer.stop("./mp3/sound/1-4.mp3");
  }

  forceUpdate() {
    super.forceUpdate(() => {
      if (this.game.isGameOver) {
        const isWin = this.game.isGameOver === GameOverType.Win;

        if (isWin) {
          systemAudioPlayer.win();
          this.sendRating(1);
        } else {
          systemAudioPlayer.lose();
          this.sendRating(0);
        }

        ConfirmService.confirm({
          message: isWin ? "Поздравляем!<br>Вы выиграли!" : "Поражение",
          buttons: ["В главное меню", "Начать заново"],
          cb: (index) => {
            if (index === 0) {
              this.exitGame();
            } else {
              this.restartGame();
            }
          },
        });
      }

      if (!(window as any).playAd) {
        (window as any).playAd = true;
        VKCheckAds();
      }

      // if (this.runAdsOnOver) {
      //   (window as any).playAd = true;
      //   VKCheckAds();
      // }

      // this.runAdsOnOver = true;
    });
  }

  sendRating(is_complete_campaign: number) {
    const relationshipServerData = (window as any)
      .relationship as RelationshipType[];
    if (!relationshipServerData) return;

    const vk_id = (window as any).vkViewerId as string;
    const treasury = this.game.gameState.variables["TREASURY"];
    const squad_power = this.game.gameState.variables["SQUAD_STRENGTH"];

    // текстовое взаимоотношение со смердами
    const relationshipSmerd = this.relationShipToText(
      this.game.gameState.variables["SMERD_RELATIONSHIP"],
    );
    const relationship_with_smerds_id = relationshipServerData.findIndex(
      (relationship) => relationship.name === relationshipSmerd,
    );

    // текстовое взаимоотношение с боярами
    const relationshipBoyar = this.relationShipToText(
      this.game.gameState.variables["BOYAR_RELATIONSHIP"],
    );
    const relationship_with_boyars_id = relationshipServerData.findIndex(
      (relationship) => relationship.name === relationshipBoyar,
    );

    if (
      vk_id &&
      isValidNumber(treasury) &&
      isValidNumber(squad_power) &&
      isValidNumber(relationship_with_smerds_id) &&
      isValidNumber(relationship_with_boyars_id)
    ) {
      updateRating({
        vk_id,
        treasury,
        squad_power,
        relationship_with_smerds_id,
        relationship_with_boyars_id,
        is_complete_campaign,
      });
    }
  }

  renderEventTexts() {
    if (this.game.currentAnswer) {
      return (
        <>
          <p
            dangerouslySetInnerHTML={{
              __html: this.game.currentAnswer.resultText.ru,
            }}
          />
          <FlexCenter>
            <SmallButton
              onClick={() => {
                this.game.moveOn();
                this.forceUpdate();
              }}
            >
              Дальше
            </SmallButton>
          </FlexCenter>
        </>
      );
    }

    if (this.game.currentEvent) {
      return (
        <div>
          <h3
            dangerouslySetInnerHTML={{
              __html: this.game.currentEvent.title.ru,
            }}
          />
          <p
            dangerouslySetInnerHTML={{ __html: this.game.currentEvent.text.ru }}
          />
          <>
            {this.game.currentEvent.answers.length ? (
              this.game.currentEvent.answers.map((answer, index) => {
                return (
                  <div
                    key={answer.choiceText.ru + index}
                    className="answer"
                    onClick={() => {
                      this.game.chooseAnswer(answer);
                      systemAudioPlayer.click();
                      this.forceUpdate();
                    }}
                    onMouseEnter={() => {
                      systemAudioPlayer.focus();
                    }}
                  >
                    {answer.choiceText.ru}
                  </div>
                );
              })
            ) : (
              <button
                onClick={() => {
                  this.game.moveOnWithoutAnswers();
                  this.forceUpdate();
                }}
              >
                Всё понятно, дальше!
              </button>
            )}
          </>
        </div>
      );
    }

    return "Нет активных событий!";
  }

  relationShipToText(relationship: number): string {
    if (relationship <= -3) return "предательское";
    if (relationship === -2) return "враждебное";
    if (relationship === -1) return "настроженное";
    if (relationship === 0) return "нейтральное";
    if (relationship === 1) return "симпатия";
    if (relationship === 2) return "уважение";
    if (relationship >= 3) return "дружелюбное";

    return "непонятные";
  }

  render() {
    return (
      <GameWindow>
        <GameContainer>
          <XBorder />
          <YBorder />
          <XBorderBottom />
          <YBorderRight />
          <YBorderMiddle />
          <XBorderMiddle1 />
          <XBorderMiddle2 />
          <MapContainer>
            {/*<img src="./img/game/game/map/map.png" />*/}
            <LiveMap src="./live-map/1.html" />
            <Gerbs gameState={this.game.gameState} />
          </MapContainer>
          <StatsContainer>
            <h3>ХОД №{this.game.gameState.variables["STEP"]}</h3>
            <div className="stat">
              <div>
                <img src="./img/game/stats/kazna.png" />
              </div>
              <span>Казна {this.game.gameState.variables["TREASURY"]}</span>
            </div>
            <div className="stat">
              <div>
                <img src="./img/game/stats/sword.png" />
              </div>
              <div>
                Сила дружины {this.game.gameState.variables["SQUAD_STRENGTH"]}
              </div>
            </div>
            <div className="stat">
              <div>
                <img
                  src={
                    this.game.gameState.variables["BOYAR_RELATIONSHIP"] < -1
                      ? "./img/game/stats/man1.png"
                      : this.game.gameState.variables["BOYAR_RELATIONSHIP"] < 2
                      ? "./img/game/stats/man2.png"
                      : "./img/game/stats/man3.png"
                  }
                />
              </div>
              <div>
                Смерды:{" "}
                {this.relationShipToText(
                  this.game.gameState.variables["SMERD_RELATIONSHIP"],
                )}
              </div>
            </div>
            <div className="stat">
              <div>
                <img
                  src={
                    this.game.gameState.variables["BOYAR_RELATIONSHIP"] < -1
                      ? "./img/game/stats/man4.png"
                      : this.game.gameState.variables["BOYAR_RELATIONSHIP"] < 2
                      ? "./img/game/stats/man5.png"
                      : "./img/game/stats/man6.png"
                  }
                />
              </div>
              <div>
                Бояре:{" "}
                {this.relationShipToText(
                  this.game.gameState.variables["BOYAR_RELATIONSHIP"],
                )}
              </div>
            </div>
            <SmallButton
              onClick={() =>
                ConfirmService.confirm({
                  message: "Выйти из игры?",
                  cb: (result) => result === 0 && this.exitGame(),
                  buttons: ["Да", "Нет"],
                })
              }
            >
              Выход
            </SmallButton>
          </StatsContainer>
          <EventPictureContainer>
            {this.game.currentAnswer && (
              <>
                {this.getTaskImage(this.game.currentAnswer.imageUrl) ||
                  "Для текущего ответа нет изображения"}
              </>
            )}
            {!this.game.currentAnswer && this.game.currentEvent && (
              <>
                {this.getTaskImage(this.game.currentEvent.imageUrl) ||
                  "Для текущего события нет изображения"}
              </>
            )}
            {!this.game.currentEvent && !this.game.currentAnswer && (
              <>Нет активных событитй и ответов - нет картинки поумолчанию</>
            )}
          </EventPictureContainer>
          <EventTextContainer>{this.renderEventTexts()}</EventTextContainer>
        </GameContainer>
      </GameWindow>
    );
  }

  private getTaskImage(src: string): React.ReactNode | null {
    return (
      (src && (
        <img
          src={src.replace("https://uhod-za-homoi.ru/img/", "./img/dynamic/")}
          alt=""
        />
      )) ||
      null
    );
  }

  private exitGame() {
    // window.location.href = "/game-loader";
    window.location.hash = "/game-loader";
  }

  private restartGame() {
    this.game = new Game(mainConfigStore.mainConfig);
    this.game.moveOn();
    this.forceUpdate();
  }
}

import {
  getRating,
  getRelationship,
  RatingUpdateDataType,
  RecordType,
  RelationshipType,
  sendRating,
  setVkAuthParams,
  VkParamsType,
} from "./records";

const toFormData = (item: any) => {
  const form_data = new FormData();

  for (const key in item) {
    form_data.append(key, item[key]);
  }

  return form_data;
};

export const loadRating = async (
  dataCb: (data: RecordType[]) => void,
  errorCb: (data: string) => void,
) => {
  try {
    const response = await getRating();

    if (response.data) {
      dataCb(response.data.data);
    } else {
      throw new Error();
    }
  } catch (error) {
    errorCb("Не удалось загрузить рекорды");
  }
};

export const loadRelationship = async (
  dataCb: (data: RelationshipType[]) => void,
  errorCb?: (data: string) => void,
) => {
  try {
    const response = await getRelationship();

    if (response.data) {
      dataCb(response.data.data);
    } else {
      throw new Error();
    }
  } catch (error) {
    errorCb && errorCb("Не удалось загрузить взаимоотношения");
  }
};

export const sendVkParams = async (
  data: VkParamsType,
  dataCb?: () => void,
  errorCb?: (data: string) => void,
) => {
  try {
    const response = await setVkAuthParams(toFormData(data));

    if (response.data) {
      dataCb && dataCb();
    } else {
      throw new Error();
    }
  } catch (error) {
    errorCb && errorCb("Не удалось отправить параметры");
  }
};

export const updateRating = async (
  data: RatingUpdateDataType,
  dataCb?: () => void,
  errorCb?: (data: string) => void,
) => {
  try {
    const response = await sendRating(toFormData(data));

    if (response.data) {
      dataCb && dataCb();
    } else {
      throw new Error();
    }
  } catch (error) {
    errorCb && errorCb("Не удалось обновить рейтинг");
  }
};

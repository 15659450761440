import bridge, { EAdsFormats } from "@vkontakte/vk-bridge";

type Callback<T extends any[] = []> = ((...args: T) => void) | undefined;

export const VKinit = () => {
  bridge.send("VKWebAppInit", {});
};

export const VKShowAds = (cb: Callback<[boolean]> = undefined) => {
  bridge
    .send("VKWebAppShowNativeAds", { ad_format: EAdsFormats.INTERSTITIAL })
    .then((data) => {
      if (data.result) {
        console.log("Реклама показана");
      } else console.log("Ошибка при показе");

      cb && cb(data.result);
    })
    .catch((error) => {
      console.log(error); /* Ошибка */
    });
};

export const VKCheckAds = (cb: Callback<[boolean]> = undefined) => {
  bridge
    .send("VKWebAppCheckNativeAds", {
      ad_format: EAdsFormats.INTERSTITIAL,
    })
    .then((data) => {
      if (data.result) {
        console.log("Предзагруженная реклама есть.");
        // Предзагруженная реклама есть.
        // Теперь можно создать кнопку
        // "Прокачать героя за рекламу".
        // ...
      } else {
        console.log("Рекламные материалы не найдены.");
      }

      cb && cb(data.result);
    })
    .catch((error) => {
      console.log(error); /* Ошибка */
    });
};

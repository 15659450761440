import React, { useEffect } from "react";
import VariablesEditor from "../../pages/VariablesEditor/VariablesEditor";
import EventsEditor from "../../pages/EventsList/EventsList";
import {
  Link,
  Route,
  Routes,
  HashRouter,
  BrowserRouter,
} from "react-router-dom";
import GameTesting from "../../pages/GameTesting/GameTesting";
import styles from "./App.module.scss";
import ExportButton from "../ExportButton/ExportButton";
import { EventType } from "../../stores/main-config.store";
import { ImportButton } from "../ImportButton/ImportButton";
import EventEditor from "../EventEditor/EventEditor";
import { useMainConfigStore } from "../../hooks/use-main-config-store";
import GamePrototypePage from "../../pages/GamePrototype/GamePrototype";
import { GameMainMenu } from "../../pages/Game/GameMainMenu";
import { GameGame } from "../../pages/Game/Game";
import { observer } from "mobx-react";
import { LoaderPage } from "../../pages/LoaderPage";
import Records from "../../pages/Records/Records";
import { loadRelationship, sendVkParams } from "../../api/service";
import {
  recordsApi,
  RECORDS_API_PATHS,
  setVkAuthParams,
} from "../../api/records";
import { VKinit } from "../../api/vkBridge";

const App =
  /*inject("mainConfigStore")(*/
  observer(function App() {
    const mainConfigStore = useMainConfigStore();

    let commonLength = 0;
    let criticalLength = 0;
    let randomLength = 0;

    mainConfigStore.mainConfig.events.forEach((e: any) => {
      switch (e.type) {
        case EventType.Common:
          commonLength++;
          break;
        case EventType.Critical:
          criticalLength++;
          break;
        case EventType.Random:
          randomLength++;
          break;
      }
    });

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has("viewer_id")) {
        (window as any).vkViewerId = urlParams.get("viewer_id");
      }

      if (urlParams.has("api_result")) {
        const get_params = urlParams.get("api_result");

        get_params && sendVkParams({ get_params });
      }

      loadRelationship((relationship) => {
        (window as any).relationship = relationship;
      });
    }, []);

    useEffect(() => {
      VKinit();
    }, []);

    return (
      <HashRouter>
        <div className={styles.app}>
          <Routes>
            <Route path="/" element={<LoaderPage />} />
            {/* <Route
            path="/common-events"
            element={<EventsEditor eventType={EventType.Common} />}
          />
          <Route
            path="/random-events"
            element={<EventsEditor eventType={EventType.Random} />}
          />
          <Route
            path="/critical-events"
            element={<EventsEditor eventType={EventType.Critical} />}
          /> */}
            {/* <Route path="/game-testing" element={<GameTesting />} /> */}
            {/* <Route path="/game-prototype" element={<GamePrototypePage />} /> */}
            <Route path="/game-loader" element={<LoaderPage />} />
            <Route path={"/game-menu"} element={<GameMainMenu />}>
              <Route path={"authors"} element={<GameMainMenu />} />
            </Route>
            <Route path={"game"} element={<GameGame />} />
            <Route path={"records"} element={<Records />} />
            {/* <Route path={"/common-events/:index"} element={<EventEditor />} />
          <Route
            path={"/critical-events/:index"}
            element={<EventEditor />}
          />
          <Route path={"/random-events/:index"} element={<EventEditor />} /> */}
          </Routes>
        </div>
      </HashRouter>
    );

    // return (
    //   <HashRouter>
    //     <div className={styles.app}>
    //       <div className={styles.header}>
    //         <nav>
    //           <Link to={"/"}>
    //             Переменные ({mainConfigStore.mainConfig.variables.length})
    //           </Link>
    //           <Link to={"/common-events"}>
    //             Сюжетные события ({commonLength})
    //           </Link>
    //           <Link to={"/random-events"}>
    //             Случайное события ({randomLength})
    //           </Link>
    //           <Link to={"/critical-events"}>
    //             Критические события ({criticalLength})
    //           </Link>
    //           <Link to={"/game-prototype"}>Игра_(тест)</Link>
    //           <Link to={"/game-testing"}>Тестирование</Link>
    //           <Link to={"/game-loader"}>Игра_(диз)</Link>
    //         </nav>
    //         <ExportButton />
    //         <ImportButton />
    //       </div>

    //       <div style={{ marginTop: "15px" }}>
    //         <Routes>
    //           <Route path="/" element={<VariablesEditor />} />
    //           <Route
    //             path="/common-events"
    //             element={<EventsEditor eventType={EventType.Common} />}
    //           />
    //           <Route
    //             path="/random-events"
    //             element={<EventsEditor eventType={EventType.Random} />}
    //           />
    //           <Route
    //             path="/critical-events"
    //             element={<EventsEditor eventType={EventType.Critical} />}
    //           />
    //           <Route path="/game-testing" element={<GameTesting />} />
    //           <Route path="/game-prototype" element={<GamePrototypePage />} />
    //           <Route path="/game-loader" element={<LoaderPage />} />
    //           <Route path={"/game-menu"} element={<GameMainMenu />}>
    //             <Route path={"authors"} element={<GameMainMenu />} />
    //           </Route>
    //           <Route path={"game"} element={<GameGame />} />
    //           <Route path={"/common-events/:index"} element={<EventEditor />} />
    //           <Route
    //             path={"/critical-events/:index"}
    //             element={<EventEditor />}
    //           />
    //           <Route path={"/random-events/:index"} element={<EventEditor />} />
    //         </Routes>
    //       </div>

    //       {/*<img src={reactLogo.default} height="480" />*/}
    //     </div>
    //   </HashRouter>
    // );
  });
/*)*/

export default App;

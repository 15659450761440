import styled from "styled-components";
import { Popup } from "../Popup";
import React, { useState } from "react";
import { PopupFace } from "../Face";
import { systemAudioPlayer } from "../../../utils/system-audio-player";
import { IGameState } from "../../../game/game-state";

type IFace = {
  isVisible: (gameStat: IGameState) => boolean;
  title: string;
  text: string;
  img: string;
  leftProc: number;
  topProc: number;
};

const FACES: IFace[] = [
  {
    isVisible: (gameState) => gameState.variables["LESHIY_RELATIONSHIP"] >= 5,
    title: "Леший",
    text: "Таинственный лесной житель. Помнит хорошие и плохие поступки. Всегда охраняет леса и лесных жителей. Ему подвластны силы природы и лесные животные.",
    img: "/img/game/game/map/faces/leshiy.png",
    leftProc: 43,
    topProc: 82,
  },
  {
    isVisible: (gameState) => gameState.variables["MERMAID"] >= 4,
    title: "Водяной",
    text: "Владыка рек и вод. Имеет несколько дочерей, которых хотел бы отдать за местных князей. Имеет несметные богатсва и большое влияние в мире нечисти.",
    img: "/img/game/game/map/faces/vodyznoy.png",
    leftProc: 61,
    topProc: 30,
  },
  {
    isVisible: (gameState) => gameState.variables["GORYNYCH_ALLIANCE"] >= 1,
    title: "Змей Горыныч",
    text: "Огромный дракон, живущий на скале. Любит дественниц. По слухам, под его скалой находятся несметные богатства. Чрезвычайно могуч.",
    img: "/img/game/game/map/faces/gorynich.jpg",
    leftProc: 47,
    topProc: 10,
  },
  {
    isVisible: (gameState) => gameState.variables["BABA_YAGA_IS_GOOD"] === 1,
    title: "Баба Яга",
    text: "Злая старуха. Ходят слухи, что она поедает детей. Однако, если держать её в узде, то может и сослужить пользу.",
    img: "/img/game/game/map/faces/yaga.png",
    leftProc: 69,
    topProc: 89,
  },
];

const GerbDiv = styled.div`
  position: absolute;
  width: 75px;
  height: 75px;
  background: url("./img/game/game/gerb.png") no-repeat center;

  :hover {
    transform: scale(1.2);
  }
`;

export function Gerb(props: { face: IFace }) {
  const [isOpen, setIsOpen] = useState(false);

  const face = props.face;

  return (
    <>
      <GerbDiv
        onClick={() => {
          systemAudioPlayer.friendClick();
          setIsOpen(true);
        }}
        onMouseEnter={() => {
          systemAudioPlayer.focus();
        }}
        style={{
          left: `${face.leftProc}%`,
          top: `${face.topProc}%`,
        }}
      />
      <Popup
        size={"medium"}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <PopupFace title={face.title} text={face.text} img={face.img} />
      </Popup>
    </>
  );
}
export function Gerbs({ gameState }: { gameState: IGameState }) {
  return (
    /**/
    <>
      {FACES.filter((face) => {
        return face.isVisible(gameState);
      }).map((face) => {
        return <Gerb key={face.title} face={face} />;
      })}
    </>
  );
}

import { Loader, AudioLoadStrategy } from "resource-loader";
import React from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { Line } from "rc-progress";
import { electronApi } from "../utils/electron-api";

const Video = styled.video`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: black;
`;
const Progress = styled.div`
  left: 10vw;
  bottom: 200px;
  width: 80vw;
  height: 20px;
  position: fixed;
  z-index: 2;
  text-align: center;
`;

type IState = {
  percentage: number;
  videoEnded: boolean;
};

class YOUclass extends AudioLoadStrategy {}

export class LoaderPage extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      percentage: 0,
      // videoEnded: false,
      videoEnded: false,
    };
  }

  componentDidMount() {
    const loader = new Loader();

    loader.onProgress.add(() => {
      this.setState({
        percentage: Math.round(loader.progress),
      });
    });

    // // FONTS
    loader.add("./fonts/russian-display/RuslanDisplay.woff");
    loader.add("./fonts/lobster/Lobster-Regular.ttf");

    // // IMAGES
    loader.add("./img/game/main-background.jpg");
    loader.add("./img/game/game/border-bg-vertical.png");
    loader.add("./img/game/game/border-bg.png");
    loader.add("./img/game/game/map/map.png");
    loader.add("./img/game/game/map/faces/vodyznoy.png");
    loader.add("./img/game/game/map/faces/yaga.png");
    loader.add("./img/game/game/map/faces/leshiy.png");
    loader.add("./img/game/game/map/faces/pipe-cleaner.png");
    loader.add("./img/game/game/map/faces/zemi-gorynych.png");
    loader.add("./img/game/game/gerb.png");
    loader.add("./img/game/game/radio.png");
    loader.add("./img/game/game/radio-hover.png");
    loader.add("./img/game/cursor/default-32.png");
    loader.add("./img/game/popup/close-hover.png");
    loader.add("./img/game/popup/close.png");
    loader.add("./img/game/popup/medium-size-bg.png");
    loader.add("./img/game/popup/small-size-bg.png");
    loader.add("./img/game/main-menu/escape-button-hover.png");
    loader.add("./img/game/main-menu/escape-button.png");
    loader.add("./img/game/main-menu/menu-hero.png");
    loader.add("./img/game/main-menu/menu-list-bg.png");
    loader.add("./img/game/main-menu/main-menu-right-bg.png");
    loader.add("./img/game/stats/boyar.png");
    loader.add("./img/game/stats/kazna.png");
    loader.add("./img/game/stats/sword.png");
    loader.add("./img/game/stats/man.png");
    loader.add("./img/game/stats/man1.png");
    loader.add("./img/game/stats/man2.png");
    loader.add("./img/game/stats/man3.png");
    loader.add("./img/game/stats/man4.png");
    loader.add("./img/game/stats/man5.png");
    loader.add("./img/game/stats/man6.png");

    // // LIVE MAP
    loader.add("./live-map/img/cloud-01.png");
    loader.add("./live-map/img/cloud-02.png");
    loader.add("./live-map/img/cloud-03.png");
    loader.add("./live-map/img/cloud-04.png");
    loader.add("./live-map/img/birds.png");
    loader.add("./live-map/img/wooden.png");
    loader.add("./live-map/img/wooden2.png");
    loader.add("./live-map/img/map.png");

    // SOUNDS
    // loader.add("./mp3/sound/1.mp3");
    // loader.add("./mp3/sound/2.mp3");
    // loader.add("./mp3/sound/3.mp3");
    // loader.add("./mp3/sound/4.mp3");

    // loader.add("./mp3/sound/Menu.mp3");
    // loader.add("./mp3/effects/ClickButton.mp3");
    // loader.add("./mp3/effects/FocusButton.mp3");
    // loader.add("./mp3/effects/FriendClick.mp3");
    // loader.add("./mp3/effects/Lose.mp3");
    // loader.add("./mp3/effects/StartGameButton.mp3");
    // loader.add("./mp3/effects/Win.mp3");
    // loader.add("./mp3/sound/1-4.mp3");

    // TEST, TODO: delete
    // loader.add({
    // url: "./mp3/sound/1-4.mp3",
    // onComplete: () => {
    //   console.log("im completed");
    // },
    // strategy: AudioLoadStrategy,
    // });

    loader.load((loader, resources) => {
      console.log(loader, resources, "loader, resources");
    });

    // loader.add("./mp3/sound/1-4.mp3").load((loader, resources) => {
    //   console.log(loader, resources, "loader, resources");
    // });
  }

  render() {
    if (
      this.state.percentage === 100
      // this.state.percentage === 100 &&
      // (this.state.videoEnded || sessionStorage.getItem("waitedOnce"))
    ) {
      sessionStorage.setItem("waitedOnce", "true");
      return <Navigate to="/game-menu" />;
    }

    // const src = electronApi
    //   ? "your-custom-protocol:///video/Final_raimonds1_Commercial_UseV2.mp4"
    //   : // fix: было - "/video/Final_raimonds1_Commercial UseV2.mp4";
    //     "/video/Final_raimonds1_Commercial_UseV2.mp4";

    return (
      <div>
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // backgroundColor: "#141414",
            background:
              "url(./img/game/main-background.jpg), linear-gradient(60.18deg,#ebe5db 28.66%,#e6e6e6 89.59%)",
          }}
        ></div>
        {this.state.percentage !== 100 && (
          <Progress>
            <Line
              percent={this.state.percentage}
              strokeColor="#ffc300"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Загрузка игровых ресурсов: {this.state.percentage}%
            </div>
          </Progress>
        )}
        {/*<PlayVideo>*/}
        {/*  <button onClick={() => this.forceUpdate()}>Play Video</button>*/}
        {/*</PlayVideo>*/}
      </div>
    );
  }
}
